
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Cabin:wght@400;700&family=Ubuntu+Mono:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}


.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}


.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Cabin", sans-serif; 
}

body {
  font-family: "Cabin", sans-serif;
}

p {
  font-family: "Cabin", sans-serif;
}


body a.link-homepage {
  color: rgb(20, 110, 117);
  font-weight: bold;
}


a.link-footer {
  color: rgb(48, 163, 172);
}

a.social-footer {
  color: rgb(48, 163, 172);
}

a.social-footer:hover {
  color: rgb(220, 248, 250);
}


a.brand-logo {
  text-decoration: none;
  font-size: 2.0rem;
  font-weight: normal;
}

.text-green {
  color: #158000;
}

.text-red {
  color: #800000;
}

.text-blue {
  color: #0085ba;
}

.text-gray {
  color: #7d7d7d;
}


.header-collapse {
  /* background-color: #000000; */
  color: white;
}

.header-nav {
  margin-left: 10px;
  margin-right: 10px;
}

.header-container {
  font-weight: bold;
}

.cursor-alias {cursor: alias;}
.cursor-all-scroll {cursor: all-scroll;}
.cursor-auto {cursor: auto;}
.cursor-cell {cursor: cell;}
.cursor-context-menu {cursor: context-menu;}
.cursor-col-resize {cursor: col-resize;}
.cursor-copy {cursor: copy;}
.cursor-crosshair {cursor: crosshair;}
.cursor-default {cursor: default;}
.cursor-e-resize {cursor: e-resize;}
.cursor-ew-resize {cursor: ew-resize;}
.cursor-grab {cursor: grab;}
.cursor-grabbing {cursor: grabbing;}
.cursor-help {cursor: help;}
.cursor-move {cursor: move;}
.cursor-n-resize {cursor: n-resize;}
.cursor-ne-resize {cursor: ne-resize;}
.cursor-nesw-resize {cursor: nesw-resize;}
.cursor-ns-resize {cursor: ns-resize;}
.cursor-nw-resize {cursor: nw-resize;}
.cursor-nwse-resize {cursor: nwse-resize;}
.cursor-no-drop {cursor: no-drop;}
.cursor-none {cursor: none;}
.cursor-not-allowed {cursor: not-allowed;}
.cursor-pointer {cursor: pointer;}
.cursor-progress {cursor: progress;}
.cursor-row-resize {cursor: row-resize;}
.cursor-s-resize {cursor: s-resize;}
.cursor-se-resize {cursor: se-resize;}
.cursor-sw-resize {cursor: sw-resize;}
.cursor-text {cursor: text;}
.cursor-w-resize {cursor: w-resize;}
.cursor-wait {cursor: wait;}
.cursor-zoom-in {cursor: zoom-in;}
.cursor-zoom-out {cursor: zoom-out;}

.version-items {
  list-style: none;
  display: inline-block;
  padding-left: 0;
}

.footer-content {
  text-align: center;
  font-size: small;
  width: 90vw !important;
}


.sidebar-nav { 
  width: "100%";
}


.sidebar-nav-link {
  /* text-transform: uppercase; */
  font-size: 0.75rem;
  line-height: 1.0rem
}

.sidebar-nav-link .active {
  color: rgb(105, 255, 118) !important;
}

.sidebar-nav-link-disabled {
  color: rgb(143, 143, 143) !important;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1.1rem;
  line-height: 1.0rem
}

button.btn-light {
  border: #636363 1px solid;
  background-color: #eee;
}

button.btn-light:hover {
  border: #afafaf 1px solid;
  background-color: #fff;
}

button.btn-orange {
  background-color: rgb(255, 165, 0);
  border: 0;
}

button.btn-orange:hover {
  background-color: rgb(168, 120, 31);
  color: #fff;
  border: 0;
}

button.btn-blue {
  background-color: rgb(30, 203, 255);
  border: 0;
}

button.btn-blue:hover {
  background-color: rgb(0, 149, 175);
  color: #fff;
  border: 0;
}

button.btn-green {
  background-color: rgb(17, 192, 31);
  border: 0;
}

button.btn-green:hover {
  background-color: rgb(15, 133, 25);
  color: #fff;
  border: 0;
}

.text-bold {
  font-weight: bold;
}

.color-grey {
  color: rgb(143, 143, 143);
}

.color-red {
  color: rgb(192, 17, 17);
}

.color-green {
  color: rgb(17, 192, 31);
}

.color-dark-green {
  color: rgb(36, 117, 43);
}

.color-blue {
  color: rgb(3, 156, 167);
}

.color-dark-blue {
  color: rgb(2, 100, 107);
}

.color-orange {
  color: rgb(255, 177, 32);  
}

.color-dark-orange {
  color: rgb(126, 82, 0);  
}

.color-white {
  color: rgb(255, 255, 255);  
}


.background-dark {
  background-color: rgb(75, 75, 75);
}

.background-light {
  background-color: rgb(175, 175, 175);
}


.background-white {
  background-color: rgb(255, 255, 255);
}

.background-green {
  background-color: rgb(17, 192, 31);
}

.background-blue {
  background-color: rgb(3, 156, 167);
}

.background-orange {
  background-color: rgb(160, 108, 12);  
}

.text-monospace {
  font-weight: bold;
  font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
}

.background-green-l {
  background-color: rgb(214, 255, 218);
}

.background-blue-l {
  background-color: rgb(172, 249, 255);
}

.background-orange-l {
  background-color: rgb(255, 231, 188);  
}

div.sidebar-col {
  min-height: calc(100vh - 235px);
  width: fit-content;
  background-color: #555555;
}

div.content-col {
  height: min-content;
  background-color: #fff;
  width: 75%;
}

.pos-content-col {
  height: min-content;
  background-color: rgb(133, 134, 148) !important;
  height: auto;
}

a.sidebar-nav-link {
  color: rgb(255, 255, 255) !important;
}

a.sidebar-nav-link:link {
  color: rgb(76, 243, 255) !important;
}

a.sidebar-nav-link:visited {
  color: rgb(50, 190, 255) !important;
}

a.sidebar-nav-link:hover {
  color: rgb(105, 255, 118) !important;
  /* text-decoration: underline; */
}

a.header-nav {
  color: rgb(255, 255, 255) !important;
}

a.header-nav:hover {
  color: rgb(105, 255, 118) !important;
  /* text-decoration: underline; */
}

a.sidebar-nav-link:active {
  color: rgb(48, 163, 172) !important;
}

.list-none {
  background-color: #555555;
  color: white;
  list-style: none;
}

.align-right {
  text-align: right!important;
}

.align-left {
  text-align: left!important;
}

.align-center {
  text-align: center!important;
}

.item-content p {
  font-size: 0.9rem;
}

.scan-to-buy {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.0rem;
  font-weight: bold;
}
.item-img-detail {
  max-width: 150px;
  width: 150px;
}

.item-detail-table {
  width: 45%;
}

/* .print-detail {
  width: min-content
} */

.text-underline {
  text-decoration: underline;
}
.text-em {
  font-style: italic;
}

.nav-text {
  vertical-align: middle;
  margin-left: 5px;
}

.cursor-pointer {cursor: pointer;}

.item-price {
  font-size: 3.0rem;
  font-weight: bold;
  font-family: 'Ubuntu Mono', monospace;
  color: rgb(35, 110, 0);
  line-height: 2.5rem;
  margin-bottom: 20px;
}

#pagination .col1{
  text-align: left;
}

#pagination .col2{
  text-align: center;
}

#pagination .col3{
  text-align: right;
}

div.floating-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.floating-button:active {
  transform: translateY(4px); 
  box-shadow: 0 0 10px 10px rgb(236, 160, 20);
  opacity: 0.25;
  border-radius: 15px;
  padding: 5px;

} 

div.payment-widget  {
  margin: 10px;
}

div.payment-widget .text-t1 {
font-weight: bold;
font-size: 1.6rem;
}

div.payment-widget .text-fiat-amount {
  font-size: 1.8rem;
  line-height: 1.6rem;
  font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #3e841e;
}

div.payment-widget .text-sats-amount {
  font-size: 1.4rem;
  line-height: 1.3rem;
  font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #636363;
}

div.payment-widget img.store-logo {
  width: 100px;
}


div.payment-widget div.payment-hash {
  margin: 3px;
  line-height: 0.75rem;
  width: 330px;
  font-size: 0.8rem;
}

div.payment-widget div.payment-instruct {
  line-height: 0.85rem;
  width: 330px;
  font-size: 0.9rem;
  text-align: center;
}

div.payment-footer {
  margin: 10px;
  width: 330px;
  line-height: 0.85rem;
  font-size: 0.9rem;
}

/* ------------ */
@media screen and (max-width: 1006px) {


  #pagination .col1{
    text-align: left;
  }

  #pagination .col2{
    text-align: center;
  }

  #pagination .col3{
    text-align: right;
  }

  div.sidebar-col {
    min-height: calc(100vh + 535px);
    background-color: #555555;
    margin: 0px;
    height: 100%;
    width: 25%;
  }
  div.content-col {
    height: min-content;
    background-color: #fff;
    width: 75%;
  }

}

@media screen and (max-width: 782px) {
  #pagination .col1{
    text-align: center;
  }

  #pagination .col2{
    text-align: center;
  }

  #pagination .col3{
    text-align: center;
  }

  div.sidebar-col {
    min-height: 100px;
    background-color: #555555;
    margin: 0px;
    width: 100%;
    height: max-content;
  }
  div.content-col {
    height: min-content;
    background-color: #fff;
    width: 100%;
  }

}

@media screen and (max-width: 600px) {
  #pagination .col1{
    text-align: center;
  }

  #pagination .col2{
    text-align: center;
  }

  #pagination .col3{
    text-align: center;
  }

  .inline-nav-links {
    display: block;
  }
  .header-nav {
    margin-left: 0px;
    margin-right: 10px;
  }
  .sidebar-col {
    background-color: #555555;
    margin: 0px;
    height: max-content;
    min-height: 100px;
  }


}

@media screen and (max-width: 420px) {
  .inline-nav-links {
    display: block;
  }
  .header-nav {
    margin-left: 0px;
    margin-right: 10px;
  }
  .sidebar-col {
    min-height: 100px;
    background-color: #555555;
    margin: 0px;
    height: max-content;
  }

}

@media screen and (max-width: 375px) {
  .inline-nav-links {
    display: block;
  }
  .header-nav {
    margin-left: 0px;
    margin-right: 10px;
  }
  .sidebar-col {
    min-height: 100px;
    background-color: #555555;
    margin: 0px;
    height: max-content;
  }


}

@media screen and (max-width: 310px) {
  .inline-nav-links {
    display: block;
  }
  .header-nav {
    margin-left: 0px;
    margin-right: 10px;
  }
  .sidebar-col {
    min-height: 90px;
    max-height: 90px;
    background-color: #555555;
    margin: 0px;
    height: max-content;
  }

}



.print-detail {
  display: none;
}

.detail-price {
  text-align: center;
  width: 100%;
}
.detail-qr {
    text-align: center;
    width: 100%;
}

.item-img-detail{
  max-width: 300px;
  width: 300px;
}

@media screen {
  .only-screen {
    display: block;
  }
  .only-print {
    display: none;
  }
}

/* override styles when printing */
@media print {
  body {
    margin: 20px auto;
  }
  .item-img-detail {
    max-width: 100%;
    width: 100%;
    vertical-align: top;
  }
  .print-detail {
      display: block;
      margin: 20px auto;
      padding: 20px auto;
  }
  .only-print {
    display: block;
  }
  .only-screen {
    display: none;
  }
  .print-button {
      display: none;
  }


}
