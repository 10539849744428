.payments-wallet{
    font-size: small;
}

.drain-wallet-section {
    margin: auto;
    padding: 5px;
}



.sats-number-wallet {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: #555;
    font-weight: bold;
}

.fiat-number-wallet {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    color: rgb(0, 124, 0);
}

.fiat-number-receipt {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    color: rgb(0, 0, 0);
}

.invoice-sats {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: rgb(0, 0, 0);
}

.invoice-action-phrase {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    color: rgb(0, 143, 24);
}

.invoice-text {
    font-size: small;
}

.wallet-section-row {
    width: 70%
}

.qr-drain {
    width: 100px
}

.balance-metric-card {
    border-radius: 10px;
    padding: 20px;
    margin: auto;
    padding: 5px;
    border: 1px rgb(189, 189, 189) solid;
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
}


@media screen and (max-width: 600px) {
    .wallet-section-row {
        width: 99%
    }
    .sats-number {
        font-size: 50px;
    }
    .accordion-body {
        padding: 0 !important;
    }
    
}

