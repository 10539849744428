.login-form {
  padding: 2rem;
}

.login-form-checkbox{
 width: fit-content;
}

.login-form-input {
  /* font-size: 1rem;
  border: 1px solid #cacaca;
  padding: 0.5rem;
  width: 100%;
  margin-bottom: 1rem; */
}

.login-form-section {
  width: 100%;
  justify-self: center;
}

p.index-subtext {
  font-size: large;
}

p.login-form {
  width: 50%;
}
