.payments-wallet {
    font-size: small;
}

.drain-wallet-section {
    margin: auto;
    padding: 5px;
}

.sats-number {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 100px;
}

.invoice-text {
    font-size: small;
}

.wallet-section-row {
    width: 70%
}

.qr-drain {
    width: 100px
}

th.item-description {
    width: 150px;
}

.product-card {
    width: 260px;
    margin: auto;
}

div.card-title {
    font-size: large;
    font-weight: bold;
}

div.card-title a {
    color: rgb(78, 78, 78);
}

.card-price {
    font-size: x-large;
    font-weight: bold;
    font-family: 'Ubuntu Mono', Courier, monospace;
}


.list-action-buttons {
    text-align: right;
}

img.hiw-star {
    width: 25%;
    margin-bottom: 30px;
}

div.product-card {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: rgb(116, 116, 116) solid 1px;
}

img.img-dots {
    width: 100px;
    margin-bottom: 20px;
}

div.card-group {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around; */
    width: 100%;
}

.card-img-top {
    width: 210px;
    vertical-align: middle;
    margin: auto;
}

span.product-pill {
    background-color: rgb(184, 121, 6);
}

.product-badge {
    border-radius: 15px;
    color: black;
    font-weight: bold;
    padding: 5px;
    font-size: x-small;
    margin: auto;
    width: min-content;
    text-align: center;
}



@media screen and (max-width: 600px) {
    .wallet-section-row {
        width: 99%
    }

    .sats-number {
        font-size: 50px;
    }

    .accordion-body {
        padding: 0 !important;
    }

}