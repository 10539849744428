.word-logo-hiw {
    font-size: 6rem;
    text-align: center;
}

.hiw-leader {
    margin-bottom: 30px;
}

.how-main-three {
    margin-bottom: 10px;
    width: 50.0em;
}

.hiw-woo {
    width: 50.0em;
}