


.metric {
    display: flex;
    background-color: #fff;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #e1e1e1;
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 10px;
    padding: 20px auto;

}

header.metric-header {
    overflow: hidden;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;
    height: 60px;
    line-height: 60px;
    width: 100%;
}


.metric-body {
    width: 100px;
    height: 100px;
    overflow: hidden;

}


.primary-value {
    color: #5BC1DE;
    font-size: 40px;
    line-height: 50%;
    margin: 0;
    padding: 5px 0;
    display: block;
    text-align: center;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.primary-label {
    color: #797979;
    font-size: 15px;
}




.viz-basic {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.value {
    text-align: center;
}

.metric-inner {}

.img-qr-used {
    width: 280px;
    height: 280px;
    margin: 0 auto;
}