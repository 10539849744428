.caculator-body{
    background: #000;
    color: white;
    font-family: 'Cabin', sans-serif;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

.c-buttons{
    display: inline-block;
    margin: 23px 15px;
}

.c-btn{
    position: relative; 
    background: #323232;
    color: white;
    padding: 7px 0px 0px 0px;
    border: 0px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 5px;
    font-size: 30px;
    cursor: pointer;
    outline: none;
}
.c-btn:focus{
    background: white;
    color: #323232;
 }

.c-btn-calc{
    background: #FF9500;
  
}
.c-btn-action{
    background: #A6A6A6;
    color: #323232;
}
.c-btn-clear{
    background: #e10000;
  
}
.c-btn-0{
    background: #323232;
    color: white;
    border: 0px;
    margin: 5px;
    padding: 5%;
    width: 130px;
    border-radius: 30px;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    outline: none;
}
.c-btn-0:focus{
    background: white;
    color: #323232;
}

.c-btn-create{
    color: white;
    border: 0px;
    margin: 5px;
    padding: 5%;
    width: 270px;
    border-radius: 30px;
    height: 60px;
    font-size: 25px;
    cursor: pointer;
    outline: none;
}
.c-btn-create:focus{
    color: #323232;
}

.send {
    background-color: rgb(149, 0, 0);
}

.receive {
    background-color: rgb(12, 149, 0);
}

.disabled {
    background-color: rgb(117, 117, 117);
}

.c-result{
    width: 100%;
    height: 62px;
    margin-top: 10px;
    background: #2c7584e0;
    border-radius: 5px;
}   

.c-result p{
    text-align: right;
    font-size: 35px;
    margin-bottom: 4px;
    padding-top: 5px;
    padding-right: 10px;
}

.create-txt {
    position: relative;
    top: -5px;
}