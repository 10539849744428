.pos-content {
  padding: 0;
  width: 100%;
  
  /* height: 70vh;
  background-color: #fff; */
}



.pos-row {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  /* align-items: center; */
  padding: 0;
  width: 100%;
  height: 90vh;
}

.timer-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(90, 90, 90);
  font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
}


.page-row.pos-content  {
  background-color: rgb(78, 78, 78);
}

/* #select-rail.btn-group.active {
  
}


input.btn-check.checked {
  -webkit-box-shadow: 0px 5px 15px 5px #FFCE24; 
  box-shadow: 0px 5px 15px 5px #FFCE24;
  background-color: blueviolet;
  color: #000;
} */


#select-rail label.btn-outline-ln{color:#ffbb02; border-color:#ffbb03; background-color:#ffffff;}
#select-rail label.btn-outline-ln:hover{color:#fff; background-color:#ff9900; border-color:#ffc400}
#select-rail label.btn-outline-ln.disabled,
#select-rail label.btn-outline-ln:disabled{color:#ffcc00;background-color:transparent}


#select-rail .btn-check:active+.btn-outline-ln, 
.btn-check:checked+.btn-outline-ln, 
.btn-outline-ln.active, 
.btn-outline-ln.dropdown-toggle.show, 
.btn-outline-ln:active {
  color: #fff !important;
  background-color: #ffbb02 !important;
  border-color: #ffbb02 !important;
  box-shadow:0 0 0 .2rem rgba(255, 200, 0, 0.5) !important;
}


#select-rail label.btn-outline-onchain{color:#9602ff; border-color:#bc03ff; background-color:#ffffff;}
#select-rail label.btn-outline-onchain:hover{color:#fff; background-color:#51096f; border-color:#c300ff}
#select-rail label.btn-outline-onchain.disabled,
#select-rail label.btn-outline-onchain:disabled{color:#ae00ff;background-color:transparent}


#select-rail .btn-check:active+.btn-outline-onchain, 
.btn-check:checked+.btn-outline-onchain, 
.btn-outline-onchain.active, 
.btn-outline-onchain.dropdown-toggle.show, 
.btn-outline-onchain:active {
  color: #fff !important;
  background-color: #af02ff !important;
  border-color: #9e02ff !important;
  box-shadow:0 0 0 .2rem rgba(212, 0, 255, 0.5) !important;
}




