.payments-wallet{
    font-size: small;
}

.drain-wallet-section {
    margin: auto;
    padding: 5px;
}

.sats-number {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 100px;
}

.invoice-text {
    font-size: small;
}

.wallet-section-row {
    width: 70%
}

.qr-drain {
    width: 100px
}

.success-item-name {
    font-size: x-large;
}

.success-description {
    font-size: medium;
}


@media screen and (max-width: 600px) {
    .wallet-section-row {
        width: 99%
    }
    .sats-number {
        font-size: 50px;
    }
    .accordion-body {
        padding: 0 !important;
    }
    
}

