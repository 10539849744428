div.payment-item-table {
    font-size: large;
}

div.clickable-download {
    cursor: pointer;
    text-decoration: underline;
}

.wrap-lnurl {
    /* inline-size: 150px; */
    overflow-wrap: break-word;
    font-family: "consolas", "courier new", monospace;
}