.cart-line {
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    background-color: #f9f9f9;
    padding: 5px;
}

/* h3.print-pos {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
} */

.print-pos {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
}

.screen-product-line {
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(13, 13, 13);
}

.print-pos-line {
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(13, 13, 13);
}

.print-pos-line .info {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8rem;
    color: #000;
}

.print-pos-line .price {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    color: #000;
}