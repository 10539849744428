body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Cabin', 'Roboto', 'Ubuntu Mono',
    'Ubuntu', 'Bree+Serif', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components { 
  .btn-common-nocolor {
    @apply m-1 p-1 flex rounded justify-center items-center;
  }
  .btn-common-blue {
    @apply m-1 p-1 rounded-md bg-blue-500 hover:bg-blue-600 text-black hover:text-white flex justify-center items-center;
  }
  .btn-common-gray {
    @apply m-1 p-1 rounded-md bg-gray-400 hover:bg-gray-500 text-black hover:text-white flex justify-center items-center;
  }
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
}
