.signup-form {
  /* padding: 2rem; */
}

.signup-form-section {
  width: 100%;
  justify-self: center;
}

p.index-subtext {
  font-size: large;
}

p.login-form {
  width: 50%;
}

.email-input {
  width: 70px;
}

@media screen and (max-width: 375px) {
  .signup-form {
    padding: 0rem;
  }

}
