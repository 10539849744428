

.dropdown:hover .dropdown-menu {
    display: block;
}
.dropdown:hover .dropdown-menu {
    display: block;
}

a.dropdown-item {
    color: black;
}

a.nav-link {
    font-family: 'Cabin', sans-serif;
    font-size: large;
}

.navbar-toggler {
    background-color: cadetblue;
}



.btn-outline-light {
    color: white;
}


.index-pos {
    width: 400px;
    text-align: center;
}

.hiw-section-img {
    width: 100% !important;
    text-align: center;
}

.wallets-list { 
    margin-bottom: 40px;
}

.wallets-section {
    background-color: #505050;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
        
  }

  .wallets-section ul {
    width: 100%;
    padding-left: 0!important;
    margin-bottom: 50px;
  }

  .wallets-section li {
    margin: 20px;
    width: 128px;
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  .img-wallet {
    width: 100px;
    border-radius: 15px;
  }


.img-wallet:hover {
	bottom: -36px;
	opacity: 1;
}

/* Zoom In #1 */
img.img-wallet  {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
img.img-wallet:hover  {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}


/* press */
.press-section {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
        
  }

  .press-section ul {
    width: 100%;
    padding-left: 0!important;
    margin-bottom: 50px;
  }

  .press-section li {
    margin: 20px;
    width: 210px;
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  /* .img-press {
  }


.img-press:hover {
	bottom: -36px;
	opacity: 1;
} */

.img-woo-sm {
    width: 200px;
}

/* Zoom In #1 */
img.img-press  {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
img.img-press:hover  {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}


.slide-frame {
    display:block; 
    margin:0 auto;
}

.hiw-section {
    background-color: grey;
}

.video-container {
    width: 700px;
    height: 400px;
}


.video-container iframe {
    width: 700px;
    height: 400px;
}

.index-logo {
    /* font-size: 6rem; */
}

.index-s-img {
    width:300px; 
    height:300px;  
}

.product-s-img {
    width:80%; 
    margin: 10px;
    border-radius: 5%;
}


.section-fee {
    background-color: #252525;
    padding: 30px;
    margin-bottom: 30px;
    color: rgb(255, 190, 86);
}

.index-section-container {
    padding: 30px;
    max-width: 880px;
    margin: 0 auto;
}

.index-section-col {
    margin: 10px;
    padding: 10px;
}

.index-section-mobile {
    width: 95%;
    margin: 0 auto;
}

.j-right {
    text-align: right;
    justify-content: right;
    align-items: right;
}

.j-left {
    text-align: left;
    justify-content: left;
    align-items: left;
}

@media screen and (max-width: 400px) {
    .hiw-circle {
        width: 50%!important;
        text-align: center;
    }   
}

  /* ------------ */
  @media screen and (max-width: 600px) {
    .wallet-section-row {
        width: 99%
    }
    .sats-number {
        font-size: 50px;
    }
    .hiw-circle {
        width: 60%!important;
        text-align: center;
    }
    .index-pos {
        width: 300px;
        text-align: center;
    }
    .hiw-section-img {
        width: 100% !important;
        height: auto;
        text-align: center;
    }
    .index-logo {
        font-size: 4rem;
    }


    .video-container {
        overflow: hidden;
        position: relative;
        width:100%;
    }
    
    .video-container::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }
    
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
}