.key-name-flex {
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(94, 94, 94);
}

.key-nv-flex {
    border-radius: 5px;
    border: 1px solid #aaa;
    background-color: #333;
    color: rgb(255, 215, 35);
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
    padding: 4px;
    width: 100%;
}

.key-nv-expires {
    font-family: 'Ubuntu Mono', 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8rem;
}

.key-invalid {
    background-color: #aaa;
    color: rgb(114, 114, 114);
    border-radius: 5px;
    margin: 5px;
}

.key-valid {
    background-color: rgb(225, 255, 209);
    color: rgb(0, 0, 0);
    border-radius: 5px;
    margin: 5px;
}


/* .border-bottom {
    border-bottom: 3px solid rgb(75, 75, 75);
} */